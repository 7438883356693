const initRequestBlock = async function() {
	// Request Text Overflow
	let descBlock = $( ".request__desc" );
	let maxHeight = parseInt( descBlock.css( "max-height" ) );
	let descBlockText = $( ".request__desc--wrap p" );

	while ( descBlockText.height() > maxHeight ) {
		descBlockText.text( function( index, text ) {
			return text.replace( /\W*\s(\S)*$/, "..." );
		} );
	}
};

themeUtils.loadBlock( initRequestBlock, "request", ".request" );
